/* src/components/AccessDenied.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .title {
    font-size: 2.5rem;
    color: #333;
  }
  
  .message {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .button {
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  