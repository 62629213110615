/* src/components/LoggedInHeader.css */
.header-container {
    width: 100%;
    height: 70px; /* Increased height */
    background-color: white;
    border-bottom: 1px solid #e0e0e0;
}

.header-content {
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 0 30px; /* Increased padding */
}

/* Logo Section */
.logo-section {
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 30px; /* Added padding */
}

.logged-in-logo {
    height: 40px; /* Increased logo size */
}

/* Navigation Links */
.nav-links {
    display: flex;
    height: 100%;
    align-items: center;
    flex-grow: 1;
    gap: 10px; /* Added gap between items */
}

.nav-item {
    padding: 8px 20px; /* Increased padding */
    height: 45px; /* Specific height for nav items */
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 15px; /* Slightly larger font */
    color: #333;
    transition: background-color 0.2s;
}

.nav-item.selected {
    background-color: #f0f0f0;
    border-radius: 6px;
}

/* Right Icons */
.right-icons {
    display: flex;
    align-items: center;
    gap: 8px;  /* Adjust spacing between icons */
  }

.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    cursor: pointer;
  }

.icon-container:hover {
    background-color: #f5f5f5;
}

.header-icon {
    width: 24px;  /* Adjust size as needed */
    height: 24px;
    object-fit: contain;
  }

.info-icon {
    font-weight: bold;
}

/* Hover effects */
.nav-item:hover:not(.selected) {
    background-color: #f8f8f8;
    border-radius: 6px;
}