.titlebar {
    width: 100%;
    text-align: center;
    height: 150px;
    background-color: #ffffff;
    color: #fff;
    margin-top: 50px;
}

.logo {
    max-width: fit-content;
    max-height: 150px;
}