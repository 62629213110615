.blog-post-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.blog-post-container h1 {
  font-family: 'Courier New', monospace;  /* This gives that typewriter-like look */
  font-size: 2.5rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  font-weight: 700;
}

.blog-post-meta {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.author-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.author-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.author-name {
  color: #000;
  font-weight: 600;
  text-decoration: none;
}

.post-date {
  color: #666;
}

.social-share {
  margin-left: auto;
  display: flex;
  gap: 0.5rem;
}

.share-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}