.payment-methods-container {
    margin-top: 20px;
  }
  
  .payment-loading {
    padding: 16px;
    text-align: center;
    color: #666;
  }
  
  .payment-error {
    margin-bottom: 16px;
  }
  
  .payment-empty {
    padding: 16px;
    border-radius: 8px;
    background-color: #f9fafb;
    border: 1px solid #e5e7eb;
  }
  
  .payment-empty p {
    color: #666;
    margin-bottom: 16px;
  }
  
  .payment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .payment-header h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
  
  .payment-add-button {
    background-color: #000000;
    color: white;
    padding: 8px 16px;
    border-radius: 13px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .payment-add-button:hover {
    background-color: #333333;
  }
  
  .payment-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .payment-card {
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 16px;
    transition: border-color 0.3s ease;
  }
  
  .payment-card:hover {
    border-color: #9ca3af;
  }
  
  .payment-card-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .payment-card-header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
  }
  
  .card-brand {
    font-weight: 600;
    text-transform: capitalize;
  }
  
  .card-number {
    color: #666;
  }
  
  .default-badge {
    background-color: #f3f4f6;
    color: #666;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
  }
  
  .expiry-date {
    font-size: 14px;
    color: #666;
    margin-bottom: 8px;
  }
  
  .billing-details {
    font-size: 14px;
    color: #666;
    margin-top: 8px;
    line-height: 1.4;
  }
  
  .payment-actions {
    display: flex;
    gap: 8px;
  }
  
  .set-default-button {
    background: none;
    border: none;
    color: #666;
    font-size: 14px;
    cursor: pointer;
    padding: 4px 8px;
    transition: color 0.3s ease;
  }
  
  .set-default-button:hover {
    color: #000;
  }
  
  .remove-button {
    background: none;
    border: none;
    color: #dc2626;
    font-size: 14px;
    cursor: pointer;
    padding: 4px 8px;
    transition: color 0.3s ease;
  }
  
  .remove-button:hover {
    color: #b91c1c;
  }


  /* Add these styles to PaymentMethods.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.modal-header h3 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
}

.modal-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #666;
  cursor: pointer;
  padding: 0;
}

.modal-close:hover {
  color: #000;
}

.modal-body {
  margin-bottom: 24px;
}

.modal-body p {
  margin: 0;
  color: #666;
  line-height: 1.5;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.modal-button {
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-button.primary {
  background-color: #000000;
  color: white;
  border: none;
}

.modal-button.primary:hover {
  background-color: #333333;
}

.modal-button.secondary {
  background-color: white;
  color: #000000;
  border: 1px solid #000000;
}

.modal-button.secondary:hover {
  background-color: #f5f5f5;
}

.success-message {
  background-color: #dcfce7;
  color: #166534;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 16px;
}

.error-message {
  background-color: #fee2e2;
  color: #991b1b;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 16px;
}