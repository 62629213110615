.contact-container {
    min-height: 100vh;
    background-color: rgb(249, 250, 251);
}

.contact-main {
    max-width: 1280px;
    margin: 0 auto;
    padding: 2rem 1rem;
}

.profile-section {
    max-width: 48rem;
    margin: 0 auto;
}

.header-content {
    display: grid;
    grid-template-columns: 1fr 320px;
    gap: 2rem;
    align-items: center;
    margin-bottom: 2rem;
}

.text-header {
    text-align: left;
}

.name-heading {
    font-size: 2.25rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: rgb(31, 41, 55);
}

.title-heading {
    font-size: 1.25rem;
    color: rgb(75, 85, 99);
    margin-top: 0.5rem;
}

.image-container-contact {
    width: 100%;
    max-width: 320px;
    justify-self: end;
}

.profile-image {
    width: 100%;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    object-fit: cover;
}

.contact-card {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    margin-bottom: 2rem;
    padding: 1.5rem;
}

.card-heading {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
}

.contact-links {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contact-item {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.contact-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: rgb(75, 85, 99);
}

.contact-link {
    color: rgb(37, 99, 235);
    text-decoration: none;
}

.contact-link:hover {
    color: rgb(30, 64, 175);
}

.bio-container {
    width: 100%;
}

.bio-content {
    text-align: left;
}

.bio-text {
    color: rgb(55, 65, 81);
    margin-bottom: 1rem;
    line-height: 1.6;
}

.tech-stack {
    margin-top: 2rem;
}

.tech-heading {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: rgb(55, 65, 81);
}

.tech-list {
    list-style-type: disc;
    margin-left: 1.5rem;
    margin-top: 0.5rem;
}

.tech-list li {
    margin-bottom: 0.5rem;
    color: rgb(55, 65, 81);
}