.my-account-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
  }
  
  .my-account-container h1 {
    text-align: center;
    margin-bottom: 40px;
    font-weight: bold;
  }
  
  .account-section {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .section-header h2 {
    margin: 0;
  }
  
  .account-section h2 {
    margin-bottom: 20px;
    font-size: 1.5em;
    font-weight: 600;
  }
  
  .info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .info-item {
    margin-bottom: 15px;
  }
  
  .info-item label {
    display: block;
    color: #666;
    font-size: 0.9em;
    margin-bottom: 5px;
  }
  
  .info-item p {
    font-size: 1.1em;
    font-weight: 500;
    margin: 0;
    line-height: 1.4;
  }
  
  .action-button,
  .edit-button {
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 13px;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .action-button:hover,
  .edit-button:hover {
    background-color: #333333;
  }
  
  .edit-button {
    font-size: 0.9em;
    padding: 8px 16px;
  }
  
  .cancel-membership {
    background-color: #dc3545;
  }
  
  .cancel-membership:hover {
    background-color: #c82333;
  }
  
  .password-form {
    max-width: 400px;
    margin: 0 auto;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #c1c1c1;
    border-radius: 13px;
    font-size: 16px;
  }
  
  .button-group {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .submit-button,
  .cancel-button {
    flex: 1;
    padding: 10px;
    border-radius: 13px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button {
    background-color: #000000;
    color: white;
    border: none;
  }
  
  .submit-button:hover {
    background-color: #333333;
  }
  
  .cancel-button {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #000000;
  }
  
  .cancel-button:hover {
    background-color: #f8f8f8;
  }
  
  .error-message {
    color: #dc3545;
    margin-bottom: 15px;
    font-size: 0.9em;
  }
  
  .success-message {
    color: #28a745;
    margin-bottom: 15px;
    font-size: 0.9em;
  }
  
  .cancel-confirmation {
    text-align: center;
    margin-top: 15px;
  }
  
  .cancel-confirmation p {
    margin-bottom: 20px;
    font-size: 1.1em;
  }
  
  .confirm-cancel {
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 13px;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .confirm-cancel:hover {
    background-color: #c82333;
  }
  
  .keep-membership {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #000000;
    border-radius: 13px;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .keep-membership:hover {
    background-color: #f8f8f8;
  }