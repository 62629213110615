.subscription-upgrade {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #eee;
  }
  
  .subscription-upgrade h4 {
    margin-bottom: 15px;
    font-weight: 600;
  }
  
  .plan-options {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .plan-option {
    flex: 1;
    padding: 15px;
    border: 2px solid #eee;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
  }
  
  .plan-option:hover {
    border-color: #000;
  }
  
  .plan-option.selected {
    border-color: #000;
    background-color: #f8f8f8;
  }
  
  .plan-option.current {
    border-color: #ccc;
    background-color: #f8f8f8;
  }
  
  .plan-name {
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .plan-price {
    font-size: 1.2em;
    color: #333;
  }
  
  .plan-savings {
    color: #22c55e;
    font-size: 0.9em;
    margin-top: 5px;
  }
  
  .current-plan {
    position: absolute;
    top: -10px;
    right: 10px;
    background-color: #000;
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.8em;
  }
  
  .upgrade-button {
    width: 100%;
    padding: 12px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .upgrade-button:hover:not(:disabled) {
    background-color: #333;
  }
  
  .upgrade-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }