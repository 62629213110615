.homepage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.homeLogo{
    width: 30%;
}

.slogan{
    color: grey;
    margin-bottom: 30px;
    margin-top: 0px;
}

.buttons{
    flex-direction: row;
    margin-bottom: 50px;
}

.btn {
    margin: 10px;
    color: white;
    background-color: black;
    padding: 10px 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #666666;
}

.divider{
    height: 5px;
    color: black;
    width: 90%;
    margin: 30px;
}

.aboutHeader{
    font-weight: bolder;
    margin-bottom: 10px;
}

.abouth1{
    text-align: center;
    font-weight: bold;
    margin-bottom: 30px;
}

.aboutbody{
    max-width: 40%;   /* Limits the width of the heading */
    margin: 0 auto;     /* Centers the h4 horizontally */
    padding: 0 10px;    /* Adds padding (space) on the left and right */
    text-align: center; /* Aligns the text in the center of the h4 */
    font-size: 30px;
    line-height: 1.6; /* Adjusts space between lines (1.6 times the font size) */
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 10px;
    margin-top: 30px;
}

/* New CSS for Social Media Section */
.home-social-media {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 40px;
}

.home-social-media a {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
}

.home-social-media a:hover {
    transform: scale(1.1);
}

.home-social-media img {
    width: 40px;
    height: 40px;
    object-fit: contain;
}