/* Navigation.css */
.navbar {
    background-color: #0a5694;
    padding: 10px;
    display: flex;
    font-weight: bold;
  }

  .navbar-link {
    color: white;
    text-decoration: none;
    padding: 5px 20px;
  }


  .navbar-link.brand-name {
    text-decoration: none;
    margin-left: 100px;
    margin-right: auto;
  }


  .navbar-link.login {
    color:white;
    background-color: black;
    border-radius: 5px;
  }

  .navbar-link.login:visited {
    color: white;
    background-color: black;
  }

  
  .navbar-link:hover {
    text-decoration: none;
    background-color: #575757;
    border-radius: 5px;
  }
  
