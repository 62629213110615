.comparison-header {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .comparison-title {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 1rem 0;
    background-color: #f5f5f5;
    padding: 2rem;
  }
  
  .strategy-image {
    max-width: 28rem;
    height: auto;
  }
  
  .account-summary {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 2rem;
  }
  
  .account-type {
    flex: 1;
  }
  
  .account-type h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .account-type ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .account-type li {
    margin-bottom: 0.5rem;
  }
  
  .vs-divider {
    width: 2px;
    background-color: #f5f5f5;
    margin: 0 1rem;
  }
  
  .explanation-section {
    margin-top: 3rem;
    padding: 2rem;
    background-color: #f5f5f5;
    border-radius: 0.5rem;
  }
  
  .explanation-section h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .explanation-section p {
    line-height: 1.6;
    color: #374151;
  }

  .warning-banner {
    width: 100%;
    background-color: #ff4d5e;
    padding: 1.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    display: flex;
  }
  
  .warning-banner p {
    text-align: center;
    color: black;
    font-weight: bold;
    text-decoration: underline;
    display: flex;
  }

  .strategy-header {
    text-align: center;
    font-weight: bold;
    padding-bottom: 1em;
  }

  .rebalance-img {
    display: flex;
    justify-content: center;
  }

  .rebalance-explanation {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  }
  
  .rebalance-explanation h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .rebalance-explanation p {
    line-height: 1.6;
    color: #555;
    margin-bottom: 1rem;
  }
  
  .example-box {
    background-color: #f8f9fa;
    padding: 1.5rem;
    border-left: 4px solid #4CAF50;
    margin: 1.5rem 0;
  }
  
  .example-box h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .example-box ul {
    list-style-type: disc;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .tip-box {
    background-color: #e8f4fd;
    padding: 1.5rem;
    border-radius: 6px;
    margin-top: 1.5rem;
  }
  
  .tip-box h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .tip-box ul {
    list-style-type: none;
  }
  
  .tip-box li {
    margin-bottom: 0.5rem;
    padding-left: 1rem;
    position: relative;
  }
  
  .tip-box li:before {
    content: "•";
    position: absolute;
    left: 0;
    color: #4CAF50;
  }