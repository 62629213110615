.books-container {
    width: 100%;
    min-height: 100vh;
    background-color: #fff;
  }
  
  .content {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 1rem;
  }
  
  .book-card {
    display: flex;
    gap: 2rem;
    padding: 2rem;
    border-bottom: 1px solid #eee;
    text-decoration: none;
    color: inherit;
    transition: background-color 0.2s ease;
  }
  
  .book-card:hover {
    background-color: #f8f8f8;
    cursor: pointer;
  }
  
  .book-image {
    flex-shrink: 0;
    width: 120px;
  }
  
  .book-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .book-details {
    flex-grow: 1;
  }
  
  .book-details h2 {
    margin: 0 0 0.5rem 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  .book-details h3 {
    margin: 0 0 1rem 0;
    font-size: 1rem;
    color: #666;
  }
  
  .book-details p {
    margin: 0;
    font-size: 0.9rem;
    line-height: 1.5;
    color: #555;
  }
  
  @media (max-width: 768px) {
    .book-card {
      flex-direction: column;
      gap: 1rem;
    }
  
    .book-image {
      width: 100%;
      max-width: 200px;
      margin: 0 auto;
    }
  }