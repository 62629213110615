/* Learn.css */
.learn-container {
    min-height: 100vh;
    background-color: #ffffff;
}

.learn-content {
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
}

.learn-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    margin-top: 20px;
}

.learn-card {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    transition: transform 0.2s, box-shadow 0.2s;
    cursor: pointer;
}

.learn-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.card-icon {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.card-content {
    flex: 1;
}

.card-content h2 {
    margin: 0 0 12px 0;
    font-size: 20px;
    color: #333;
}

.card-content p {
    margin: 0;
    font-size: 14px;
    color: #666;
    line-height: 1.5;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .learn-content {
        padding: 20px;
    }

    .learn-grid {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .learn-card {
        padding: 16px;
    }
}