/* Investment Comparison Styles */

.comparison-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .comparison-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }
  
  .table-wrapper {
    overflow-x: auto;
    margin-bottom: 2rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
  }
  
  .comparison-table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
  }
  
  .comparison-table th,
  .comparison-table td {
    padding: 1rem;
    border: 1px solid #e2e8f0;
    text-align: left;
  }
  
  .comparison-table th {
    background-color: #f8fafc;
    font-weight: bold;
  }
  
  .comparison-table tr:nth-child(even) {
    background-color: #f8fafc;
  }
  
  .feature-cell {
    font-weight: 500;
  }
  
  .notes-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .note {
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid;
  }
  
  .note-warning {
    background-color: #fff7ed;
    border-color: #fed7aa;
  }
  
  .note-info {
    background-color: #eff6ff;
    border-color: #bfdbfe;
  }
  
  .note-default {
    background-color: #f8fafc;
    border-color: #e2e8f0;
  }
  
  .note-title {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .note ul {
    list-style-type: disc;
    padding-left: 1.5rem;
    margin-top: 0.5rem;
  }
  
  .note li {
    margin-bottom: 0.5rem;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .comparison-container {
      padding: 0.5rem;
    }
    
    .comparison-table th,
    .comparison-table td {
      padding: 0.75rem;
      font-size: 0.875rem;
    }
    
    .note {
      padding: 0.75rem;
      font-size: 0.875rem;
    }
  }

  /* Add these styles to your existing CSS */

.comparison-header {
    margin-bottom: 2rem;
    background-color: #f8fafc;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.comparison-title {
    text-align: center;
    color: #1f2937;
    margin-bottom: 1.5rem;
}

.account-summary {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 2rem;
    margin-top: 1.5rem;
}

.account-type {
    flex: 1;
    max-width: 400px;
    padding: 1.5rem;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #e5e7eb;
}

.account-type h3 {
    color: #1f2937;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}

.account-type p {
    color: #4b5563;
    font-size: 1rem;
    margin-bottom: 1rem;
    font-style: italic;
}

.account-type ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.account-type ul li {
    color: #374151;
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
    position: relative;
}

.account-type ul li:before {
    content: "•";
    position: absolute;
    left: 0.5rem;
    color: #3b82f6;
}

.vs-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #6b7280;
    font-size: 1.25rem;
}

@media (max-width: 768px) {
    .account-summary {
        flex-direction: column;
        gap: 1rem;
    }

    .vs-divider {
        padding: 0.5rem 0;
    }

    .comparison-header {
        padding: 1rem;
        margin: 1rem;
    }

    .account-type {
        padding: 1rem;
    }
}