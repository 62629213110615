.pricing-section {
    margin: 30px 0;
  }
  
  .pricing-title {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
  
  .plans-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .plans-grid {
    display: flex;
    gap: 1rem;
  }
  
  .plan-card {
    flex: 1;
    padding: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .plan-card:hover {
    border-color: #000;
  }
  
  .plan-card.selected {
    border-color: #000;
    background-color: #f8f8f8;
  }
  
  .plan-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  
  .plan-header h4 {
    font-weight: 500;
    margin: 0;
  }
  
  .plan-radio {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }
  
  .plan-price {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
  }
  
  .plan-billing {
    font-size: 0.875rem;
    color: #666;
  }
  
  .plan-savings {
    font-size: 0.875rem;
    font-weight: 500;
    color: #22c55e;
    margin-top: 0.25rem;
  }
  
  .price-breakdown {
    border-top: 1px solid #e0e0e0;
    padding-top: 1rem;
    margin-top: 1rem;
  }
  
  .breakdown-row {
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
  
  .breakdown-row.total {
    font-weight: bold;
    font-size: 1rem;
    border-top: 1px solid #e0e0e0;
    padding-top: 0.5rem;
    margin-top: 0.5rem;
  }
  
  .tax-note {
    font-size: 0.75rem;
    color: #666;
    margin-top: 0.5rem;
  }