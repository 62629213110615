.dashboard {
  width: 100%;
  min-height: 92.5vh;
  background-color: #0a5694;
  padding: 0.5rem;  /* Reduced from 1rem */
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

/* Main dashboard edit/save button */
.dashboard-controls .control-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  border-radius: 0.375rem;
  background-color: rgb(75, 85, 99);
  color: white;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border: none;
}

.dashboard-controls .control-button:hover {
  background-color: rgb(107, 114, 128);
}

.dashboard-controls .control-button.save-button {
  background-color: #4CAF50;
  color: white;
}

.dashboard-controls .control-button.save-button:hover {
  background-color: #45a049;
}

/* Graph control buttons (revert to original styling) */
.chart-controls .control-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  background-color: rgb(229, 231, 235);
  color: rgb(55, 65, 81);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border: none;
}

.chart-controls .control-button:hover {
  background-color: rgb(209, 213, 219);
}

.chart-controls .control-icon {
  width: 1rem;
  height: 1rem;
  color: rgb(55, 65, 81); /* Dark gray for graph controls */
}

/* Dashboard controls icon */
.dashboard-controls .control-icon {
  width: 1rem;
  height: 1rem;
  color: white;
}

.control-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  border-radius: 0.375rem;
  background-color: rgb(75, 85, 99); /* Darker gray background */
  color: white; /* White text for better contrast */
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border: none;
}

.control-button:hover {
  background-color: rgb(107, 114, 128); /* Slightly lighter on hover */
}

.control-button.save-button {
  background-color: #4CAF50;
  color: white;
}

.control-button.save-button:hover {
  background-color: #45a049;
}

.control-icon {
  width: 1rem;
  height: 1rem;
  color: white; /* Ensure icon is also white */
}

.dashboard-grid-container {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 0.25rem;  /* Add minimal padding */
}

.dashboard-grid {
  margin: 0 auto;
}

.chart-wrapper {
  background-color: rgb(229, 231, 235);
  border: 2px solid black;
  padding: 0.5rem;
  border-radius: 0.375rem;
  height: 100%;
  transition: box-shadow 0.3s ease;
  cursor: default;
}

.chart-wrapper:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Edit mode styles */
.react-grid-item.react-grid-placeholder {
  background: rgb(209, 213, 219);
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  border-radius: 0.375rem;
  user-select: none;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
  cursor: grabbing;
}

/* Existing chart styles */
.chart-card {
  background-color: rgb(229, 231, 235);
  padding: 0.5rem;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.chart-title {
  font-size: 0.875rem;
  font-weight: 500;
  color: rgb(55, 65, 81);
}

.chart-controls {
  display: flex;
  gap: 0.5rem;
}

.chart-container {
  background-color: white;
  padding: 0.5rem;
  border-radius: 0.25rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chart-subtitle {
  font-size: 0.75rem;
  text-align: center;
  color: rgb(75, 85, 99);
  margin-bottom: 0.5rem;
}

.chart-content {
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: center;
  min-height: 300px;
}

.chart-content iframe {
  width: 100%;
  height: 100%;
  border: none;
}