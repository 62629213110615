.footer-container {
    background-color: #0a5694;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .logo img {
    width: 100px; /* Adjust this value to fit your footer */
    height: auto;
  }
  
  .nav-links {
    display: flex;
    gap: 20px;
  }
  
  .nav-links a {
    color: white;
    text-decoration: none;
    font-size: 16px;
  }
  
  .nav-links a:hover {
    text-decoration: underline;
  }
  
  .social-media {
    display: flex;
    gap: 15px;
  }
  
  .social-media img {
    width: 30px;
    height: 30px;
  }
  
  .social-media a {
    display: inline-block;
  }
  
  .disclaimer-text {
    display: block;
    text-decoration: none;
    color: #6c757d;
    font-size: 0.75rem;
    line-height: 1.5;
    text-align: justify;
    background-color: #0a5694;
    color: white;
    padding: 2rem;
    /* border-radius: 4px; */
  }
  
  