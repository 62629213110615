.signup-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    text-align: center;
  }
  
  .signup-logo {
    width: 200px;
    margin-bottom: 30px;
  }
  
  .progress-indicator {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    position: relative;
  }
  
  .progress-indicator::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 25%;
    right: 25%;
    height: 2px;
    background-color: #e0e0e0;
    z-index: 1;
  }
  
  .step {
    width: 120px;
    padding: 8px 0;
    background: #ffffff;
    border: 2px solid #e0e0e0;
    border-radius: 20px;
    color: #888;
    font-weight: bold;
    position: relative;
    z-index: 2;
    margin: 0 20px;
  }
  
  .step.active {
    border-color: #000000;
    background-color: #000000;
    color: white;
  }
  
  .signup-form {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .form-section {
    margin-bottom: 30px;
    text-align: left;
  }
  
  .form-section h3 {
    margin-bottom: 15px;
    color: #333;
    font-size: 18px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-row {
    display: flex;
    gap: 20px;
  }
  
  .form-group.half {
    flex: 1;
    margin-bottom: 20px;
  }
  
  .form-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #c1c1c1;
    border-radius: 13px;
    font-size: 16px;
    transition: border-color 0.3s ease;
    background-color: white;
  }
  
  .form-input.disabled-input {
    background-color: #f5f5f5;
    color: #666;
    cursor: not-allowed;
  }
  
  .info-text {
    display: block;
    color: #666;
    font-size: 12px;
    margin-top: 4px;
    font-style: italic;
  }
  
  .form-select {
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
    cursor: pointer;
  }
  
  .form-select:focus {
    outline: none;
    border-color: #000000;
  }
  
  .form-input:focus {
    outline: none;
    border-color: #000000;
  }
  
  .card-element-container {
    padding: 12px;
    border: 1px solid #c1c1c1;
    border-radius: 13px;
    background-color: white;
    transition: border-color 0.3s ease;
  }
  
  .card-element-container:focus-within {
    border-color: #000000;
  }
  
  .error {
    color: #ff0000;
    font-size: 14px;
    margin-top: 5px;
    display: block;
    text-align: left;
  }
  
  .error-message {
    color: #ff0000;
    background-color: #fff0f0;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .button-group {
    display: flex;
    gap: 20px;
    margin-top: 30px;
  }
  
  .next-btn,
  .back-btn,
  .submit-btn {
    padding: 12px 30px;
    border-radius: 13px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
  }
  
  .next-btn,
  .submit-btn {
    background-color: #000000;
    color: white;
    border: 1px solid #000000;
  }
  
  .back-btn {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #000000;
  }
  
  .next-btn:hover,
  .submit-btn:hover {
    background-color: #333333;
  }
  
  .back-btn:hover {
    background-color: #f5f5f5;
  }
  
  .next-btn:disabled,
  .submit-btn:disabled,
  .back-btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  @media (max-width: 600px) {
    .signup-container {
      padding: 10px;
    }
    
    .step {
      width: 100px;
      font-size: 14px;
      margin: 0 10px;
    }
  
    .form-row {
      flex-direction: column;
      gap: 0;
    }
  
    .form-group.half {
      width: 100%;
    }
  }

  .terms-section {
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid #eee;
  }
  
  .terms-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
  }
  
  .terms-checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .terms-checkbox {
    margin-right: 10px;
    cursor: pointer;
  }
  
  .terms-label {
    font-size: 14px;
    color: #333;
    user-select: none;
  }
  
  .terms-link {
    color: #000;
    text-decoration: underline;
  }
  
  .terms-link:hover {
    color: #666;
  }