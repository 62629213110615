/* Container Styling */
.blog-post-creation-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    font-family: Arial, sans-serif;
  }
  
  /* Form Styling */
  .blog-post-creation-container form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  /* Input Fields */
  .blog-post-creation-container input[type="text"],
  .blog-post-creation-container select {
    width: 100%;
    padding: 0.75rem;
    border: 2px solid #0a5694;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
  }
  
  .blog-post-creation-container input[type="text"]:focus,
  .blog-post-creation-container select:focus {
    outline: none;
    border-color: #0a5694;
    box-shadow: 0 0 0 2px rgba(10, 86, 148, 0.2);
  }
  
  /* Labels */
  .blog-post-creation-container label {
    color: #0a5694;
    font-weight: bold;
    margin-bottom: 0.5rem;
    display: block;
  }
  
  /* Content Block Styling */
  .content-block {
    background-color: #f8f9fa;
    padding: 1.5rem;
    border-radius: 8px;
    border: 1px solid #dee2e6;
    margin-bottom: 1rem;
  }
  
  /* Button Styling */
  .blog-post-creation-container button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  /* Primary Button (Submit) */
  .blog-post-creation-container button[type="submit"] {
    background-color: #0a5694;
    color: white;
    width: 200px;
    margin: 2rem auto;
  }
  
  .blog-post-creation-container button[type="submit"]:hover {
    background-color: #084477;
    transform: translateY(-1px);
  }
  
  /* Secondary Buttons (Add/Remove Block) */
  .content-block button {
    background-color: white;
    color: #0a5694;
    border: 2px solid #0a5694;
    margin-right: 1rem;
    margin-top: 1rem;
  }
  
  .content-block button:hover {
    background-color: #0a5694;
    color: white;
  }
  
  /* Rich Text Editor (React Quill) Customization */
  .quill {
    margin-top: 1rem;
  }
  
  .ql-toolbar {
    border-color: #0a5694 !important;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  
  .ql-container {
    border-color: #0a5694 !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    min-height: 200px;
  }
  
  /* Image Preview */
  .selected-image {
    margin: 1rem 0;
    text-align: center;
  }
  
  .selected-image img {
    max-width: 300px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* File Input Styling */
  input[type="file"] {
    border: 2px dashed #0a5694;
    padding: 1rem;
    border-radius: 4px;
    width: 100%;
    cursor: pointer;
    margin: 0.5rem 0;
  }
  
  /* Error States */
  .error-message {
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
  
  input.error,
  select.error {
    border-color: #dc3545;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .blog-post-creation-container {
      padding: 1rem;
    }
  
    .content-block {
      padding: 1rem;
    }
  
    .blog-post-creation-container button {
      width: 100%;
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
  }