.terms-and-conditions-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .terms-and-conditions-modal {
    background-color: white;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 500px;
    width: 100%;
  }
  
  .terms-and-conditions-content {
    margin-bottom: 1.5rem;
  }
  
  .terms-and-conditions-checkbox {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
  
  .terms-and-conditions-checkbox input {
    margin-right: 0.5rem;
  }
  
  .terms-and-conditions-actions {
    display: flex;
    justify-content: flex-end;
  }
  
  .terms-and-conditions-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  
  .terms-and-conditions-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }