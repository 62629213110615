/* src/assets/styles/LeaveConfirmationPopup.css */
.leave-confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
  }
  
  .leave-confirmation-content {
    width: 400px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  .leave-confirmation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .leave-confirmation-header h3 {
    margin: 0;
    font-size: 18px;
    color: #333;
  }
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.2s;
  }
  
  .close-button:hover {
    background-color: #f0f0f0;
  }
  
  .leave-confirmation-body {
    padding: 20px;
    font-size: 16px;
    color: #555;
  }
  
  .leave-confirmation-footer {
    padding: 16px 20px;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    border-top: 1px solid #e0e0e0;
  }
  
  .primary-button, .secondary-button {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    border: none;
  }
  
  .primary-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .primary-button:hover {
    background-color: #45a049;
  }
  
  .secondary-button {
    background-color: #f5f5f5;
    color: #333;
  }
  
  .secondary-button:hover {
    background-color: #e0e0e0;
  }