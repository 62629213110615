.submit-idea-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 0 20px;
}

.submit-idea-title {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
}

.submit-idea-form {
    display: flex;
    gap: 20px;
    align-items: flex-start;
}

.idea-textarea {
    flex: 1;
    min-height: 100px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    resize: vertical;
}

.idea-textarea:focus {
    outline: none;
    border-color: #0066cc;
}

.submit-button {
    background-color: #333;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    white-space: nowrap;
}

.submit-button:hover {
    background-color: #444;
}

.submit-button:active {
    background-color: #222;
}