.pricing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pricing-title {
    font-size: 1.875rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  
  .pricing-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
  }
  
  .pricing-card {
    background-color: white;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 16rem;
  }
  
  .plan-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .price {
    font-size: 1.875rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
  }
  
  .interval {
    color: #6b7280;
    margin-bottom: 1rem;
  }
  
  .features {
    margin-bottom: 1.5rem;
    padding-left: 0;
    list-style-type: none;
  }
  
  .features li {
    font-size: 0.875rem;
    color: #4b5563;
  }
  
  .purchase-button {
    width: 100%;
    background-color: #1f2937;
    color: white;
    padding: 0.5rem 0;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .purchase-button:hover {
    background-color: #374151;
  }