.add-card-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .add-card-modal {
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .modal-header h2 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0;
    color: #666;
  }
  
  .close-button:hover {
    color: #000;
  }
  
  .add-card-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .card-element-container {
    border: 1px solid #c1c1c1;
    padding: 12px;
    border-radius: 13px;
  }
  
  .form-row {
    display: flex;
    gap: 16px;
  }
  
  .form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 4px;
    font-size: 0.9rem;
    color: #666;
  }
  
  .form-group input,
  .form-group select {
    padding: 10px;
    border: 1px solid #c1c1c1;
    border-radius: 13px;
    font-size: 16px;
  }
  
  .form-group input:focus,
  .form-group select:focus {
    outline: none;
    border-color: #000;
  }
  
  .checkbox-group {
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  
  .checkbox-group input[type="checkbox"] {
    width: auto;
    margin-right: 8px;
  }
  
  .error-message {
    color: #dc2626;
    font-size: 0.9rem;
    padding: 8px;
    background-color: #fef2f2;
    border-radius: 4px;
  }
  
  .button-group {
    display: flex;
    gap: 16px;
    margin-top: 8px;
  }
  
  .submit-button,
  .cancel-button {
    flex: 1;
    padding: 12px;
    border-radius: 13px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button {
    background-color: #000000;
    color: white;
    border: none;
  }
  
  .submit-button:hover:not(:disabled) {
    background-color: #333333;
  }
  
  .cancel-button {
    background-color: white;
    color: #000000;
    border: 1px solid #000000;
  }
  
  .cancel-button:hover:not(:disabled) {
    background-color: #f5f5f5;
  }
  
  .submit-button:disabled,
  .cancel-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  @media (max-width: 640px) {
    .form-row {
      flex-direction: column;
    }
    
    .add-card-modal {
      width: 95%;
      padding: 16px;
    }
  }