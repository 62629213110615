.promo-container {
    max-width: 400px;
    margin: 2rem auto;
}

.promo-link {
    text-decoration: none;
    color: inherit;
    display: block;
}

.promo-content {
    background-color: #ccff00;
    padding: 2rem;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.promo-content:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.promo-logo {
    /* width: 24px;
    height: 24px; */
    width: 20%;
    height: 20%;
    object-fit: contain;
}

.promo-text {
    font-size: 16px;
    line-height: 1.5;
    font-weight: bold;
    text-align: center;
    color: #000;
}

@media (max-width: 768px) {
    .promo-container {
        margin: 1.5rem 1rem;
    }
    
    .promo-content {
        padding: 1.5rem;
        flex-direction: column;
        text-align: center;
        gap: 1rem;
    }
}