.add-charts-container {
  width: 100%;
  min-height: 92.5vh;
  background-color: #0a5694;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.add-charts-search {
  padding: 1rem;
  margin: 0 auto 2rem auto;
  width: 100%;
  max-width: 800px;
  z-index: 10;
}

.add-charts-search-input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: all 0.2s ease-in-out;
}

.add-charts-search-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.2);
}

.add-charts-search-input::placeholder {
  color: #94a3b8;
}

.add-charts-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(450px, 1fr)); /* Fixed 3 columns */
  gap: 2rem;
  padding: 0 2rem;
  width: calc(100% - 4rem);
  margin: 0 auto;
  justify-content: center; /* Center the grid items */
}

.add-charts-item {
  background-color: rgb(229, 231, 235);
  padding: 0.5rem;
  border-radius: 0.375rem;
  border: 2px solid black;
  min-height: 300px; /* Reduced height */
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.add-charts-empty-state {
  text-align: center;
  padding: 1rem;
  color: rgb(156, 163, 175);
  font-size: 1rem;
}

/* Responsive adjustments */
@media (max-width: 1400px) {
  .add-charts-grid {
    grid-template-columns: repeat(2, minmax(400px, 1fr));
    gap: 1.5rem;
    padding: 0 1.5rem;
  }
}

@media (max-width: 1024px) {
  .add-charts-grid {
    grid-template-columns: repeat(1, minmax(400px, 1fr));
    gap: 1rem;
    padding: 0 1rem;
  }
}

@media (max-width: 640px) {
  .add-charts-grid {
    grid-template-columns: 1fr;
    padding: 0 0.5rem;
  }
  
  .add-charts-search {
    padding: 0.75rem;
    margin-bottom: 1rem;
  }
  
  .add-charts-search-input {
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
  }

  .add-charts-item {
    min-height: 250px;
  }
}