/* ResetPassword.css */
.dialogue {
    width: 300px;
    height: auto;
    text-align: center;
    background-color: #ffffff;
    margin: 0 auto;
    font-size: 1em;
    color: #000000;
  }
  
  .inputs {
    border-radius: 13px;
    padding: 10px;
    border: 1px solid #c1c1c1;
    font-weight: bold;
    margin: 3px;
    width: 90%;
  }
  
  .submitbtn {
    background-color: #000000;
    border-radius: 13px;
    padding: 10px;
    border: 1px solid #000;
    font-weight: bold;
    margin: 20px auto;
    width: 96.5%;
    color: white;
  }
  
  .submitbtn:hover:not(:disabled) {
    cursor: pointer;
    background-color: #6f6d6d;
  }
  
  .submitbtn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .errormessage {
    color: rgba(252,3,7,1.00);
    font-size: 24px;
    background-color: rgba(255,255,255,1.00);
    font-weight: bold;
    text-align: center;
    margin: 20px auto;
    max-width: 80%;
  }