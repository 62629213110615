/* src/components/AccountDropdown.css */
.account-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 8px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    min-width: 120px;
    z-index: 1000;
  }
  
  .account-dropdown::before {
    content: '';
    position: absolute;
    top: -4px;
    right: 10px;
    width: 8px;
    height: 8px;
    background-color: white;
    transform: rotate(45deg);
    box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.05);
  }
  
  .dropdown-item {
    padding: 12px 16px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .dropdown-item:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .logout {
    color: #ff3333;
    font-weight: 500;
  }
  
  /* Make sure the icon container in LoggedInHeader has position: relative */
  .icon-container {
    position: relative;
  }