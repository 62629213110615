.dialogue {
    width: 300px;
    height: auto;
    text-align: center;
    background-color: #ffffff;
    margin: 0 auto;
    font-size: 1em;
    color: #000000;
}

.inputs {
    border-radius: 13px;
    padding: 10px;
    border: 1px solid #c1c1c1;
    font-weight: bold;
    margin: 3px;
    width: 90%;
}

.googlebtn {
    background-color: #c1c1c1;
    border-radius: 13px;
    padding: 10px;
    border: 1px solid #000;
    font-weight: bold;
    margin: 20px auto;
    width: 96.5%;
    color: rgb(0, 0, 0);
}

.submitbtn {
    background-color: #000000;
    border-radius: 13px;
    padding: 10px;
    border: 1px solid #000;
    font-weight: bold;
    margin: 20px auto 10px;
    width: 96.5%;
    color: white;
}

.createaccountbtn {
    display: inline-block;
    background-color: #ffffff;
    border-radius: 13px;
    padding: 10px;
    border: 1px solid #000;
    font-weight: bold;
    margin: 0 auto 20px;
    width: 90%;
    color: #000000;
    text-decoration: none;
}

.submitbtn:hover {
    cursor: pointer;
    background-color: #6f6d6d;
}

.createaccountbtn:hover {
    cursor: pointer;
    background-color: #f5f5f5;
}

.forgot {
    color: #BBBBBB;
}

.forgot:hover {
    cursor: pointer;
    color: #6f6d6d;
}

.errormessage {
    color: rgba(252,3,7,1.00);
    font-size: 24px;
    background-color: rgba(255,255,255,1.00);
    font-weight: bold;
}