/* BlogList.css */

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.blog-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.blog-list-container {
  width: 100%;
  max-width: 600px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.toggle-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.toggle-button {
  padding: 8px 16px;
  border: 1px solid #e0e0e0;
  background-color: white;
  cursor: pointer;
  font-size: 14px;
}

.toggle-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.toggle-button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.toggle-button.active {
  background-color: #f0f0f0;
}

.blog-item {
  border-bottom: 1px solid #e0e0e0;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.blog-item:hover {
  transform: translateY(-3px);
  cursor: pointer;
  border-radius: 3px;
  border-color: #333;
}

.blog-item:last-child {
  border-bottom: none;
}

.blog-item-content {
  flex-grow: 1;
}

.blog-item-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.blog-item-date,
.blog-item-author {
  font-size: 14px;
  color: #666;
  margin-bottom: 3px;
}

.blog-item-rating {
  display: flex;
  align-items: center;
}

.star-rating {
  color: gold;
  font-size: 18px;
}

.heart-icon {
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 8px;
}

.heart-icon svg {
  fill: transparent;
  stroke: #0a5694;
  stroke-width: 2;
  transition: all 0.3s ease;
}

.heart-icon.liked svg {
  fill: #ff4444;
  stroke: #ff4444;
}

.heart-icon:hover {
  transform: scale(1.1);
}

/* Optional animation for like action */
@keyframes heartPop {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.heart-icon.liked svg {
  animation: heartPop 0.3s ease;
}

.blog-item-likes {
  color: #666;
  font-size: 0.9em;
  margin-top: 4px;
}

.heart-icon.liked {
  color: #ff4444;
}

.show-more-container {
  text-align: center;
  padding: 20px 0;
}

.show-more-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #333;
  text-decoration: none;
  transition: all 0.2s ease;
}

.show-more-button:hover {
  background-color: #e5e5e5;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px 0;
}

.pagination-button {
  padding: 8px 16px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.pagination-button:hover:not(:disabled) {
  background-color: #e5e5e5;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-indicator {
  color: #666;
  font-size: 0.9em;
}