/* Existing styles */
.chart-placeholder {
  width: 100%;
  height: 100%;
  border: none;
}

.dashboard-close-button {
  background: none;
  border: none; /* Remove border */
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.dashboard-close-button:hover {
  background-color: #f3f4f6; /* Light gray background on hover */
  border-radius: 0.375rem; /* Border radius only on hover */
}

.control-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s;
}

.control-button:hover {
  opacity: 0.7;
}

.control-icon {
  width: 20px;
  height: 20px;
}

/* New styles for expanded view */
.hidden {
  display: none;
}

.expanded-overlay {
  position: fixed;
  top: 60px; /* Adjust this value to match your header height */
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.expanded-content {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.expanded-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #eee;
}

.expanded-chart {
  width: 100%;
  height: 100%;
  border: none;
}

.close-button {
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.close-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}